import { get, post } from '../axios'

export default {
  // 申请查看证书
  updateApplyViewArchives(param) {
    return post('user/archives/updateApplyViewArchives', param)
  },

  // 首页 查看记录信息
  getArchivesInfo(param = {}) {
    return get('user/archives/getArchivesInfo', param)
  },

  // 获取申请记录列表
  getUserViewArchivesList(param = {}) {
    return post('user/archives/getUserViewArchivesList', param)
  },

  // 通过或者拒绝申请查看档案
  updateHandleViewApply(param) {
    return post('user/archives/updateHandleViewApply', param)
  },

  // 获取授权码
  getEmpowerCode(param = {}) {
    return get('user/archives/getEmpowerCode', param)
  },

  // 扫码授权
  updateEmpowerViewArchives(param = {}) {
    return get('user/archives/updateEmpowerViewArchives', param)
  },
  // 添加内部联系人
  addInsideContacts(param) {
    return post('/user/addressBook/addInsideContacts', param)
  },
}
