<template>
  <van-popup v-model="show" position="bottom">
    <div class="ZddAddMember">
      <div class="title">添加员工<van-icon name="cross" @click="close" /></div>
      <div class="desc">如有批量添加员工的需求请在电脑端操作。</div>
      <van-form
        key="checkForm"
        ref="checkForm"
        validate-first
        :show-error="false"
        @submit="addInsideContacts"
      >
        <van-field
          v-model="param.telephone"
          name="telephone"
          placeholder="请输入员工手机号"
          :clearable="true"
          :show-error="false"
          :error="false"
          :rules="rules.telephone"
        ></van-field>
        <van-field
          v-model="param.name"
          name="name"
          placeholder="请输入员工姓名（选填项）"
          :clearable="true"
          :show-error="false"
          :error="false"
        ></van-field>
        <van-field
          v-model="param.position"
          name="position"
          placeholder="请输入员工岗位（选填项）"
          :clearable="true"
          :show-error="false"
          :error="false"
        ></van-field>
        <van-field
          v-model="param.jobNum"
          name="jobNum"
          placeholder="请输入员工工号（选填项）"
          :clearable="true"
          :show-error="false"
          :error="false"
        ></van-field>
        <van-field
          v-model="param.education"
          name="education"
          placeholder="请输入员工学历（选填项）"
          :clearable="true"
          :show-error="false"
          :error="false"
        ></van-field>
        <div class="buttonGroup">
          <van-button plain type="primary" native-type="button" @click="close"
            >取消</van-button
          >
          <van-button type="primary" native-type="submit">确定</van-button>
        </div>
      </van-form>
    </div>
  </van-popup>
</template>

<script>
  import archivesApi from '@/api/user/archives'
  import { validateMobileNumber } from '@/utils/validate'

  export default {
    name: 'ZddAddMember',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
        param: {
          telephone: '', // 手机
          name: '', // 姓名
          position: '', // 岗位
          jobNum: '', // 工号
          education: '', // 学历
        },
        rules: {
          telephone: [
            {
              validator: this.validateMobile,
              trigger: 'onBlur',
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号',
              trigger: 'onBlur',
            },
          ],
        },
      }
    },
    watch: {
      value: {
        handler(newValue) {
          if (newValue) {
            this.open()
          } else {
            this.close()
          }
        },
        immediate: true,
      },
      show: {
        handler(newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    computed: {},
    created() {},
    methods: {
      // 验证手机号
      validateMobile(val) {
        return validateMobileNumber(val)
      },
      open() {
        this.param.telephone = ''
        this.param.name = ''
        this.param.position = ''
        this.param.jobNum = ''
        this.param.education = ''
        this.show = true
      },
      close() {
        this.show = false
      },
      async addInsideContacts() {
        try {
          const param = {
            addStatus: 0,
            list: [this.param],
          }
          const data = await archivesApi.addInsideContacts(param)
          this.$toast({
            message: '已经成功添加员工，在颁发证书时可查看已添加的员工。',
            duration: 2000,
          })
          this.close()
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .ZddAddMember {
    padding: 16px;
    .title {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      text-align: left;
      color: #333333;
      line-height: 25px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .desc {
      font-size: 13px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 18px;
      margin-bottom: 6px;
    }
    .van-form {
    }
    .buttonGroup {
      display: flex;
      .van-button {
        flex: 1;
      }
      .van-button + .van-button {
        margin-left: 16px;
      }
    }
  }
</style>
