<template>
  <div class="zdd-home">
    <div class="zdd-home-header">
      <div class="zdd-home-header-identity" @click="handleShowCompanyUpdate">
        <div class="zdd-home-header-identity-current">
          <div class="zdd-home-header-identity-current-name">
            Hi，
            <template v-if="isComUser">
              {{ comName }}
            </template>
            <template v-else>
              {{ userName || telephone }}
            </template>
          </div>

          <span class="arrow"></span>
        </div>
        <div class="zdd-home-header-identity-change-button">
          <van-button
            class="button-item"
            type="primary"
            size="mini"
            plain
            round
            v-if="!isComUser && !companyList.length"
            @click="handleCreateCompany"
          >
            <van-icon name="add" />创建企业</van-button
          >
          <van-button
            class="button-item"
            type="primary"
            size="mini"
            plain
            round
            v-if="isComUser && !isComCredit"
            @click.stop="handleToComCreditPage"
          >
            认证企业</van-button
          >
        </div>
      </div>
      <div class="zdd-home-header-banner">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img
              class="zdd-home-header-banner-img"
              :src="isComUser ? zddHomeBannerComImg : zddHomeBannerUserImg"
              alt=""
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              class="zdd-home-header-banner-img"
              src="@/assets/imgs/zdd_profile_com_invite.png"
              alt=""
              @click="ZddInvitPopup = true"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <template v-if="isComUser">
        <div class="zdd-home-header-com-search">
          <div class="zdd-home-com-title">
            <div class="zdd-home-com-title-main">
              <div class="zdd-home-com-title-main-order">1</div>
              <div class="zdd-home-com-title-main-text">查档案</div>
              <div class="zdd-home-com-title-main-description">
                招聘时，查询档案识别优秀人才
              </div>
            </div>
          </div>
          <div class="zdd-home-header-com-search-container">
            <input
              v-model="searchTelephone"
              class="zdd-home-header-com-search-container-input"
              placeholder="请输入手机号"
              @keydown.enter="updateApplyViewArchives"
            />
            <div
              class="zdd-home-header-com-search-container-button"
              @click="updateApplyViewArchives"
            >
              <SvgIcon type="zdd_home_search" class="icon" />
              <span class="zdd-home-header-com-search-container-button__text"
                >申请查看档案</span
              >
            </div>
          </div>
          <div class="zdd-home-header-application" v-if="allViewNum > 0">
            <div class="zdd-home-title">
              <div class="zdd-home-title-main">
                <div class="zdd-home-title-text">查看申请</div>
                <div
                  class="zdd-home-header-application-count"
                  v-if="canViewNum"
                >
                  {{ canViewNum > 99 ? '99+' : canViewNum }}
                </div>
              </div>
              <div class="zdd-home-title-more" @click="handleToRecordPage">
                全部记录
                <van-icon name="arrow" />
              </div>
            </div>
            <div
              v-if="lastPendViewRecordInfo && lastPendViewRecordInfo.viewId"
              class="zdd-home-header-application-card"
            >
              <ZddCertificateRecordItem
                is-com
                :item="lastPendViewRecordInfo"
                @updateRecord="getArchivesInfo"
              />
            </div>
            <div v-else class="zdd-home-header-application-no-last-record">
              暂无待处理申请
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="zdd-home-header-application" v-if="allViewNum > 0">
          <div class="zdd-home-title" @click="handleToRecordPage">
            <div class="zdd-home-title-main">
              <div class="zdd-home-title-text">查看申请</div>
              <div
                class="zdd-home-header-application-count"
                v-if="pendViewNum > 0"
              >
                {{ pendViewNum > 99 ? '99+' : pendViewNum }}
              </div>
            </div>
            <div class="zdd-home-title-more" @click="handleToRecordPage">
              全部记录
              <van-icon name="arrow" />
            </div>
          </div>
          <div
            class="zdd-home-header-application-card"
            v-if="lastPendViewRecordInfo && lastPendViewRecordInfo.viewId"
          >
            <ZddCertificateRecordItem
              :is-com="false"
              :item="lastPendViewRecordInfo"
              @updateRecord="getArchivesInfo"
            />
          </div>
          <div v-else class="zdd-home-header-application-no-last-record">
            暂无待处理申请
          </div>
        </div>
      </template>
    </div>
    <div class="zdd-home-main">
      <template v-if="isComUser">
        <div class="zdd-home-main-com">
          <div class="zdd-home-com-title">
            <div class="zdd-home-com-title-main">
              <div class="zdd-home-com-title-main-order">2</div>
              <div class="zdd-home-com-title-main-text">建档案</div>
              <div class="zdd-home-com-title-main-description">
                入职后，先建档后发证
              </div>
            </div>
            <div class="zdd-home-com-title-other">
              <van-button
                type="primary"
                size="small"
                plain
                @click="handleAddMember"
              >
                <van-icon name="add-o" />
                添加员工
              </van-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="zdd-home-title zdd-home-main-title left">
          <div class="zdd-home-title-main">
            <div class="zdd-home-title-text">我的档案</div>
          </div>
          <div
            v-if="!isCredit"
            class="zdd-home-title-more"
            @click="handleToCertificateDetail"
          >
            详情
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="zdd-home-main-user" v-if="isCredit">
          <div class="zdd-home-main-user-detail">
            <div class="zdd-home-main-user-detail-avatar">
              <img
                class="zdd-home-main-user-detail-avatar-img"
                :src="userInfo.headImg || defaultAvatar"
                alt="avatar"
              />
            </div>
            <div class="zdd-home-main-user-detail-info">
              <span class="zdd-home-main-user-detail-info-name" v-if="userName">
                {{ userName }} ·
              </span>
              <span class="zdd-home-main-user-detail-info-telephone">
                {{ telephone }}
              </span>
            </div>
          </div>
          <div class="zdd-home-main-user-operate">
            <van-button
              type="primary"
              plain
              v-if="hasRealName"
              @click="handleToCertificateDetail"
            >
              <SvgIcon type="zdd_home_file" class="icon" />
              查看我的档案
            </van-button>
            <van-button
              type="primary"
              plain
              v-if="hasRealName"
              @click="handleToSharePage($event, false)"
            >
              <SvgIcon type="zdd_share_auth" class="icon" />
              授权查看档案
            </van-button>
            <van-button type="primary" v-else @click="handleToRealNamePage">
              <SvgIcon type="zdd_home_user_credit" class="icon" />
              实名认证 创建档案
            </van-button>
          </div>
        </div>
        <div class="zdd-home-main-user2" v-else>
          <div class="zdd-home-main-user-detail">
            <div class="zdd-home-main-user-detail-avatar">
              <img
                class="zdd-home-main-user-detail-avatar-img"
                :src="userInfo.headImg || defaultAvatar"
                alt="avatar"
              />
            </div>
            <div class="zdd-home-main-user-detail-info">
              <span class="zdd-home-main-user-detail-info-name" v-if="userName">
                {{ userName }}
              </span>
              <span class="zdd-home-main-user-detail-info-telephone">
                {{ telephone }}
              </span>
            </div>
          </div>
          <div class="zdd-home-main-user-operate">
            <van-button type="primary" @click="handleToRealNamePage">
              <SvgIcon type="zdd_home_user_credit" class="icon" />
              实名认证 创建档案
            </van-button>
          </div>
        </div>
        <div class="zdd-home-main-certificate-list">
          <div
            class="no-certificate-invite"
            v-if="!issuedCardNum"
            @click="handleToSharePage($event, true)"
          >
            <img src="@/assets/imgs/zdd_certificate_invite.png" alt="" />
          </div>
          <ZddCertificateItem
            v-for="item in certificateList"
            :key="item.id"
            :item="item"
          />
        </div>
      </template>
    </div>

    <!--  3 发证书  -->
    <div class="zdd-home-main" v-if="isComUser">
      <div class="zdd-home-main-com">
        <div class="zdd-home-com-title">
          <div class="zdd-home-com-title-main">
            <div class="zdd-home-com-title-main-order">3</div>
            <div class="zdd-home-com-title-main-text">发证书</div>
            <div class="zdd-home-com-title-main-description">
              日常管理中，颁发高光证书激励优秀员工
            </div>
          </div>
        </div>
        <div class="zdd-home-main-com-certificate-list">
          <ZddCertificateItem
            v-for="item in certificateList"
            :key="item.id"
            :item="item"
          />
        </div>
      </div>
    </div>
    <!--  3 发证书  -->
    <ZddCompanyUpdate
      v-model="showZddCompanyUpdate"
      :cur-com-id="userInfo.comId"
      @updateSuccess="onUpdateIdentitySuccess"
    />
    <ZddInvitPopup
      :show="ZddInvitPopup"
      @close="closeInvitPopup"
    ></ZddInvitPopup>
    <ZddAddMember v-model="showZddAddMember"></ZddAddMember>
  </div>
</template>

<script>
  import ZddCertificateItem from '@/components/Zdd/ZddCertificateItem.vue'
  import ZddCertificateRecordItem from '@/components/Zdd/ZddCertificateRecordItem.vue'

  import zddHomeBannerComImg from '@/assets/imgs/zdd_home_banner_com.png'
  import zddHomeBannerUserImg from '@/assets/imgs/zdd_home_banner_user.png'
  import defaultAvatar from '@/assets/imgs/default_img.svg'

  import { SHARE_TYPE } from '@/common/zddEnum'
  import { validateMobileNumber } from '@/utils/validate'
  import archivesApi from '@/api/user/archives'
  import certApis from '@/api/core/cert'
  import getCreditToken from '@/utils/getCreditToken'
  import { authShare, inviteShare } from '@/utils/zdd'
  import { hasAuth } from '@/utils/hasAuth'
  import ZddCompanyUpdate from '@/components/Zdd/ZddCompanyUpdate.vue'
  import ZddInvitPopup from '@/components/Zdd/ZddInvitPopup.vue'
  import ZddAddMember from '@/components/Zdd/ZddAddMember.vue'
  import { mapGetters } from 'vuex'
  import { copyText } from '@/utils/copy'

  export default {
    name: 'ZddHome',
    components: {
      ZddCertificateItem,
      ZddCertificateRecordItem,
      ZddCompanyUpdate,
      ZddInvitPopup,
      ZddAddMember,
    },
    computed: {
      ...mapGetters({
        companyList: 'companyList',
      }),
      userInfo() {
        return this.$store.getters.getUserInfo
      },
      userName() {
        return this.userInfo.name
      },
      telephone() {
        const { telephone } = this.userInfo
        if (telephone) {
          return telephone.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
        }
        return ''
      },
      comName() {
        return this.$store.getters.getUserInfo.comName
      },
      isCredit() {
        return this.$store.getters.isCredit
      },
      isComUser() {
        return this.$store.getters.isComUser
      },
      isComCredit() {
        return this.$store.getters.isComCredit
      },
      hasRealName() {
        return this.$store.getters.hasRealName
      },
    },
    data() {
      this.defaultAvatar = defaultAvatar
      this.zddHomeBannerComImg = zddHomeBannerComImg
      this.zddHomeBannerUserImg = zddHomeBannerUserImg
      return {
        searchTelephone: undefined,
        // 最近的一条申请记录
        lastPendViewRecordInfo: {},
        // 待处理的申请记录有几条
        pendViewNum: 0,
        canViewNum: 0,
        allViewNum: 0,
        // 证书列表
        certificateList: [],
        // 已获取/已颁发证书
        issuedCardNum: 0,

        // 查询 loading
        searching: false,
        showZddCompanyUpdate: false,
        ZddInvitPopup: false,
        showZddAddMember: false,
      }
    },
    created() {
      this.fetchData()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      copyText,
      hasAuth,
      closeInvitPopup() {
        this.ZddInvitPopup = false
      },
      async fetchData() {
        try {
          this.getArchivesInfo()
          this.getCertList()
        } catch (error) {
          console.error(error)
        }
      },
      handleToRecordPage() {
        this.$router.push({
          name: 'ZddRecord',
        })
      },
      handleToSharePage(e, invite = false) {
        if (invite) {
          inviteShare()
        } else {
          authShare()
        }
      },
      async handleToRealNamePage() {
        const type = 2
        const token = await getCreditToken(type)
        if (token) {
          this.$router.push({
            name: 'nameAndIdentitycard',
            query: {
              token,
            },
          })
        }
      },

      handleToCertificateDetail() {
        try {
          this.$router.push({
            name: 'ZddDetail',
          })
        } catch (error) {
          console.error(error)
        }
      },

      handleToComCreditPage() {
        this.$router.push({
          name: 'AuthSubmit',
        })
      },

      handleCreateCompany() {
        this.$router.push({
          name: 'CreateCompany',
        })
      },

      handleAddMember() {
        this.showZddAddMember = true
        // this.$dialog
        //   .confirm({
        //     title: '提示',
        //     message:
        //       '公众号暂不支持添加员工，请前往电脑端浏览器搜索<span style="color:#1676ff">zdd.yizhiqian.cn</span>',
        //     confirmButtonText: '复制链接',
        //   })
        //   .then(() => {
        //     this.copyText('zdd.yizhiqian.cn')
        //   })
        //   .catch(() => {})
      },

      async getArchivesInfo() {
        try {
          const {
            pendViewNum,
            canViewNum,
            allViewNum,
            lastPendViewRecordInfo,
          } = await archivesApi.getArchivesInfo()
          this.pendViewNum = pendViewNum
          this.canViewNum = canViewNum
          this.allViewNum = allViewNum
          this.lastPendViewRecordInfo = lastPendViewRecordInfo
        } catch (error) {
          console.error(error)
        }
      },

      async onUpdateIdentitySuccess() {
        this.fetchData()
      },

      async updateApplyViewArchives() {
        if (!this.hasAuth(['addressBook_queryArchives'])) {
          this.$message.error('您当前没有查询档案的权限，请向管理员申请')
          return
        }
        let loadingInstance
        try {
          if (this.searching) return
          this.searching = true
          if (!this.searchTelephone) {
            this.$toast({
              message: '请输入手机号',
            })
            return
          }
          if (!validateMobileNumber(this.searchTelephone)) {
            this.$toast({
              message: '请确认手机号格式是否正确',
            })
            return
          }
          loadingInstance = this.$toast.loading({
            message: '处理中',
            loadingType: 'spinner',
          })

          const model = {
            telephone: this.searchTelephone,
          }
          await archivesApi.updateApplyViewArchives(model)
          this.$toast.success({
            message: '已短信通知，对方同意后，24小时内可查看',
          })
        } catch (error) {
          console.error(error)
        } finally {
          this.searching = false
          if (loadingInstance) {
            loadingInstance.close()
          }
        }
      },

      async getCertList() {
        try {
          const {
            issuedCardNum,
            list,
            waitSignCertNum,
          } = await certApis.getCertList()

          this.certificateList = list || []
          this.issuedCardNum = issuedCardNum
        } catch (error) {
          console.error(error)
        }
      },
      handleShowCompanyUpdate() {
        this.showZddCompanyUpdate = true
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-home {
    background: #f3f3f3;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    .zdd-home-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-main {
        display: flex;
        align-items: center;
        .zdd-home-title-text {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: 600;
          color: #262626;
          line-height: 24px;
          &:before {
            content: '';
            width: 3px;
            height: 16px;
            background: @BLUE;
            margin-right: 8px;
          }
        }
        .zdd-home-header-application-count {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 6px;
          padding: 0 6px;
          background: #e14141;
          border-radius: 9px;
          font-size: 13px;
          font-weight: 400;
          color: @WHITE;
          height: 18px;
          // line-height: 18px;
        }
      }
      &-more {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: var(--main-color);
        line-height: 13px;
      }
      &.left {
        justify-content: flex-start;
        .zdd-home-title-more {
          margin-left: 16px;
        }
      }
    }
    .zdd-home-header {
      background: @WHITE;
      padding-bottom: 24px;
      transition: all 0.25s ease;
      .zdd-home-header-identity {
        width: 100%;
        height: 55px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-current {
          font-weight: 600;
          color: @TEXT-COLOR-0;
          font-size: 16px;
          display: flex;
          align-items: center;
          .zdd-home-header-identity-current-name {
            max-width: 135px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .arrow {
            margin-left: 7px;
            display: inline-flex;
            align-items: center;
            border-top: 5px solid #111a34;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 0;
          }
        }
        &-change-button {
          margin-left: 12px;
          display: flex;
          .button-item {
            padding: 0 8px;
            .van-button__content {
              .van-button__text {
                display: inline-flex;
                align-items: center;
                font-size: 13px;
                .van-icon {
                  margin-right: 4px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .zdd-home-header-banner {
        margin-top: 5px;
        width: 100%;
        height: 110px;
        padding: 0 16px;
        overflow: hidden;
        .zdd-home-header-banner-img {
          width: 100%;
          height: 100%;
        }
      }
      .zdd-home-header-application {
        margin-top: 24px;
        padding: 0 16px;
        &-card {
          margin-top: 16px;
          .item {
            margin-bottom: 0;
          }
        }
        &-no-last-record {
          padding-left: 10px;
          margin-top: 11px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 18px;
        }
      }
    }
    &-main {
      margin-top: 10px;
      padding: 24px 16px;
      display: flex;
      flex: 1;
      flex-direction: column;
      background: @WHITE;
      &-title {
        &-other {
          display: flex;
          align-items: center;
          margin-left: 16px;
          font-size: 13px;
          font-weight: 400;
          color: #4285f4;
          cursor: pointer;
          .van-icon {
            margin-top: 1px;
          }
        }
      }
      &-user {
        margin-top: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 rgba(210, 210, 210, 0.5);
        padding: 18px 24px 24px;
        position: relative;
        &:before {
          content: '';
          left: 0;
          top: 0;
          position: absolute;
          width: 200%;
          height: 200%;
          border: 1px solid #d9d9d9;
          transform-origin: 0 0;
          transform: scale(0.5) translate3d(0, -1px, 0);
          z-index: 0;
        }
        &-detail {
          display: flex;
          align-items: center;
          z-index: 9;
          transform: translate3d(0, 1px, 0);
          &-avatar {
            z-index: 9;
            margin-top: -36px;
            &-img {
              width: 54px;
              height: 54px;
              border-radius: 50%;
            }
          }
          &-info {
            margin-left: 12px;
            font-size: 15px;
            font-weight: 500;
            color: #4b505f;
            line-height: 15px;
            &-name {
              font-size: 13px;
              margin-bottom: 8px;
            }
            &-telephone {
              font-size: 13px;
            }
          }
        }
        &-operate {
          margin-top: 16px;
          display: flex;
          align-items: center;
          .van-button {
            height: 32px;
            padding: 0 14px;
            .van-button__text {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              white-space: nowrap;
              .icon {
                line-height: 1;
                margin-right: 5px;
                font-size: 16px;
              }
            }
          }
          .van-button + .van-button {
            margin-left: 26px;
          }
        }
      }
      &-user2 {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .zdd-home-main-user-detail {
          display: flex;
          align-items: center;
          .zdd-home-main-user-detail-avatar {
            margin-top: 0;
            .zdd-home-main-user-detail-avatar-img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }
          }
          .zdd-home-main-user-detail-info {
            margin-left: 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .zdd-home-main-user-detail-info-name {
            }
            .zdd-home-main-user-detail-info-telephone {
            }
          }
        }
        .zdd-home-main-user-operate {
          margin-top: 0;
          display: flex;
          align-items: center;
        }
      }
      &-certificate-list {
        margin-top: 30px;
        .no-certificate-invite {
          width: 100%;
          height: 78px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .zdd-certificate-item {
          margin-top: 16px;
        }
      }
    }

    .zdd-home-header-com-search {
      margin-top: 34px;
      padding: 0 18px 0 34px;
      &-container {
        margin-top: 12px;
        display: flex;
        height: 40px;
        border: 1px solid #398aff;
        border-radius: 4px;
        padding: 0 20px;
        &-input {
          border: none;
          font-size: 14px;
        }
        &-button {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: center;
          .icon {
            font-size: 14px;
            margin-right: 4px;
          }
          &__text {
            font-size: 14px;
            font-weight: 500;
            color: var(--main-color);
            line-height: 22px;
          }
        }
      }
      .zdd-home-header-application {
        padding: 0;
      }
    }
    &-main-com {
      padding-left: 18px;
    }
    &-com-title {
      position: relative;
      display: flex;
      &-main {
        &-order {
          position: absolute;
          font-size: 32px;
          font-family: Helvetica, sans-serif;
          color: #1676ff;
          line-height: 38px;
          font-weight: 600;
          left: -24px;
          top: -10px;
          font-style: italic;
        }
        &-text {
          font-size: 17px;
          font-weight: 600;
          color: #262626;
          line-height: 24px;
        }
        &-description {
          margin-top: 6px;
          font-size: 13px;
          color: rgba(17, 26, 52, 0.5);
          line-height: 24px;
        }
      }
      &-other {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .van-button {
          padding: 0 16px;
          .van-button__text {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 13px;
            .van-icon {
              font-size: 14px;
              margin-right: 4px;
              opacity: 0.5;
            }
          }
        }
      }
    }
    .zdd-home-main-com-certificate-list {
      .zdd-certificate-item {
        margin-top: 16px;
      }
    }
    .zdd-home-main-com + .zdd-home-main-com {
      margin-top: 16px;
    }
  }
</style>
