import { get, post } from '../axios'

export default {
  // 获取证书相关信息
  getCertList(param) {
    return get('core/cert/getCertList', param)
  },
  // 获取档案详情
  getArchivesDetails(param) {
    return get('core/cert/getArchivesDetails', param)
  },
}
