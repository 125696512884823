<template>
  <li class="item" @click="handleToDetail">
    <template v-if="isCom">
      <div class="item-info">
        <div class="info">
          <span class="name">{{ item.comName || item.name }}</span>
        </div>
        <div class="status" :style="{ color: color[index] }">
          {{ RECORD_VIEW_STATUS_TYPE_TEXT[item.viewStatus] }}
          {{ item.leftTime }}
        </div>
      </div>
      <div class="item-tag">
        <div class="tag">
          <span class="label point">查看方式：</span>
          <span class="value">{{ RECORD_VIEW_TYPE_TEXT[item.viewType] }}</span>
        </div>
        <div class="tag">
          <span class="label point">申请时间：</span>
          <span class="value">{{ item.createTime }}</span>
        </div>
      </div></template
    >
    <template v-else>
      <div class="item-info">
        <div class="info">
          <span class="name">{{ item.comName }}</span>
        </div>
      </div>
      <div class="item-tag">
        <div class="tag">
          <span class="label point">查看方式：</span>
          <span class="value">{{ RECORD_VIEW_TYPE_TEXT[item.viewType] }}</span>
        </div>
        <div class="tag">
          <span class="label point">申请时间：</span>
          <span class="value">{{ item.createTime }}</span>
        </div>
      </div>
      <div class="item-handle">
        <div class="status" :style="{ color: color[item.viewStatus] }">
          {{ RECORD_VIEW_STATUS_TYPE_TEXT[item.viewStatus] }}
          {{ item.leftTime }}
        </div>
        <div class="button-group" v-if="isPending">
          <van-button
            type="primary"
            plain
            @click="updateHandleViewApply(RECORD_VIEW_STATUS_TYPE.REJECT)"
            >驳回申请</van-button
          >
          <van-button
            type="primary"
            plain
            @click="updateHandleViewApply(RECORD_VIEW_STATUS_TYPE.SUCCESS)"
            >同意查看</van-button
          >
        </div>
      </div>
    </template>
  </li>
</template>

<script>
  import {
    RECORD_VIEW_TYPE_TEXT,
    RECORD_VIEW_STATUS_TYPE,
    RECORD_VIEW_TYPE,
    RECORD_VIEW_STATUS_TYPE_TEXT,
  } from '@/common/zddEnum'
  import { hasAuth } from '@/utils/hasAuth'
  import archivesApi from '@/api/user/archives'
  /**
   * 申请记录
   */
  const color = {
    [RECORD_VIEW_STATUS_TYPE.PENDING]: '#E14141', // 待处理
    [RECORD_VIEW_STATUS_TYPE.SUCCESS]: '#f69704', // 可查看
    [RECORD_VIEW_STATUS_TYPE.REJECT]: 'rgba(0,0,0,0.45)', // 已驳回
    [RECORD_VIEW_STATUS_TYPE.EXPIRED]: 'rgba(0,0,0,0.45)', // 已过期
    [RECORD_VIEW_STATUS_TYPE.INVALID]: 'rgba(0,0,0,0.45)', // 申请失效
  }
  export default {
    name: 'ZddCertificateRecordItem',
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },

      isCom: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        RECORD_VIEW_TYPE: Object.freeze(RECORD_VIEW_TYPE),
        RECORD_VIEW_STATUS_TYPE: Object.freeze(RECORD_VIEW_STATUS_TYPE),
        RECORD_VIEW_TYPE_TEXT: Object.freeze(RECORD_VIEW_TYPE_TEXT),
        RECORD_VIEW_STATUS_TYPE_TEXT: Object.freeze(
          RECORD_VIEW_STATUS_TYPE_TEXT
        ),
        index: 0,
        color: Object.freeze(color),
      }
    },
    computed: {
      isPending() {
        return this.item.viewStatus === RECORD_VIEW_STATUS_TYPE.PENDING
      },
      isSuccess() {
        return this.item.viewStatus === RECORD_VIEW_STATUS_TYPE.SUCCESS
      },
    },
    methods: {
      hasAuth,
      async updateHandleViewApply(status) {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '处理中',
            loadingType: 'spinner',
          })
          const model = { viewId: this.item.viewId, status }
          await archivesApi.updateHandleViewApply(model)
          if (status === this.RECORD_VIEW_STATUS_TYPE.SUCCESS) {
            this.$toast({
              message: '对方可在24小时内查询您的档案',
              duration: 2000,
            })
            this.item.viewStatus = this.RECORD_VIEW_STATUS_TYPE.SUCCESS
          } else if (status === this.RECORD_VIEW_STATUS_TYPE.REJECT) {
            this.$toast({
              message: '已驳回',
              duration: 2000,
            })
            this.item.viewStatus = this.RECORD_VIEW_STATUS_TYPE.REJECT
          }

          this.$emit('updateRecord', {
            viewId: this.item.viewId,
            status,
          })
        } catch (error) {
          console.error(error)
        } finally {
          if (loadingInstance) {
            loadingInstance.close()
          }
        }
      },

      handleToDetail() {
        if (!this.isSuccess || !this.hasAuth(['addressBook_queryArchives'])) {
          return
        }
        this.$router.push({
          name: 'ZddDetail',
          query: {
            userId: this.item.userId,
          },
        })
      },
    },
  }
</script>

<style scoped lang="less">
  .item {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 0px rgba(210, 210, 210, 0.5);
    margin-bottom: 16px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border: 1px solid #d9d9d9;
      transform-origin: 0 0;
      transform: scale(0.5);
      border-radius: 4px;
    }
    .status {
      font-size: 12px;
      line-height: 28px;
      font-weight: 400;
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
    }
    .point {
      display: flex;
      align-items: center;
      &:before {
        display: flex;
        content: '';
        width: 3px;
        height: 3px;
        background: #858585;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .item-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .info {
        display: flex;
        align-items: center;
        .name {
          font-size: 15px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #3a3a3a;
          line-height: 21px;
          margin-right: 10px;
        }
        .job {
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #3a3a3a;
          line-height: 18px;
        }
      }
    }
    .item-tag {
      .tag {
        display: flex;
        align-items: center;
        .label {
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #858585;
          line-height: 18px;
        }
        .value {
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #3a3a3a;
          line-height: 18px;
        }
      }
      .tag + .tag {
        margin-top: 3px;
      }
    }
    .item-handle {
      padding-top: 16px;
      border-top: 1px dashed #d2d2d2;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button-group {
        display: flex;
        .van-button {
          width: 76px;
          height: 28px;
          opacity: 1;
          border: 1px solid #0265ff;
          border-radius: 2px;
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #0265ff;
          line-height: 18px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .van-button + .van-button {
          margin-left: 16px;
        }
      }
    }
  }
</style>
