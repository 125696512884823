<template>
  <div class="zdd-certificate-item" @click="goDetail">
    <div class="zdd-certificate-item-icon">
      <img :src="item.logo" alt="" />
    </div>
    <div class="zdd-certificate-item-info">
      <div class="zdd-certificate-item-info-title">{{ item.name }}</div>
      <div class="zdd-certificate-item-info-description">
        {{ item.desc }}
      </div>
    </div>

    <div class="zdd-certificate-item-tag">
      <span class="zdd-certificate-item-tag-text"
        >{{ isComUser ? '已颁发' : '已获得' }}
        <span class="zdd-certificate-item-tag-count">{{ item.number }}</span> 次
      </span>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ZddCertificateItem',
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters({
        isComUser: 'isComUser',
      }),
    },
    methods: {
      goDetail() {
        this.$router.push({
          path: '/zdd/recordDetail',
          query: {
            name: this.item.name,
            fileUrl: this.item.fileUrl,
            businessId: this.item.id,
          },
        })
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-certificate-item {
    padding: 20px 14px 20px;
    border: 1px solid #398aff;
    border-radius: 4px;
    display: flex;
    position: relative;
    align-items: flex-start;
    &-icon {
      display: flex;
      width: 34px;
      height: 34px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 16px;
      overflow: hidden;
      &-title {
        font-size: 15px;
        font-weight: 500;
        color: #292929;
        line-height: 15px;
      }
      &-description {
        margin-top: 11px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &-tag {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      font-weight: 400;
      color: #1676ff;
      background: rgba(229, 242, 255, 0.79);
      border-radius: 0 3px 0 0;
      padding: 2px 8px 2px 18px;
      display: flex;
      align-items: center;
      &-count {
        font-weight: 500;
      }
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border-left: 8px solid @WHITE;
        border-top: 9px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 9px solid transparent;
      }
    }
  }
</style>
